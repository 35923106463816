<script>
export default {
  name: "FilterComponent",
  props: {
    years: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    filterEvent: {
      type: Function,
      required: true
    }
  }
}
</script>

<template>
  <div class="filter">
    <div>
      <label>Поиск: </label>
      <input type="text" class="form-control" placeholder="Укажите номер заказа или фио заказчика" v-model="fields.value" @change="filterEvent()" />
    </div>
    <div>
      <select class="form-control" v-model="fields.year" @change="filterEvent()">
        <option v-for="year in years" :value="year">{{ year }}</option>
      </select>
    </div>
    <div>
      <input type="checkbox" v-model="fields.onlyNotConfirm" @change="filterEvent()"/>
      <label>только не проверенные</label>
    </div>
  </div>
</template>

<style scoped>

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: #efefef;
  padding: 5px;
}

.filter>div {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.filter>div:first-child {
  width: 350px;
}

.filter>div:first-child label {
  margin-right: 5px;
}

.filter>div:first-child input {
  width: 100%;
}

.filter>div:last-child input {
  margin-right: 5px;
}

</style>
