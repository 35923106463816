<template>
    <div>
      <div class="notification-outside" @click="hideEvent"></div>
      <div class="notification">
        <div class="header">
          <h3>Уведомления</h3>
          <a href="#" @click="handleReadAllMessage">Все прочитано</a>
        </div>
        <div class="notification-items">
          <div
            v-for="(item,key) in items"
            :key="key"
            :class="['notification-item',item.read===0?'not-read':'']"
          >
            <template v-if="item.read===0">
              <a href="#" onclick="return false;" @mouseenter="handleReadMessage(item)">
                <div class="table">
                  <div class="tr">
                    <div class="td">
                      <div class="icon">
                        <font-awesome-icon icon="rss" />
                      </div>
                    </div>
                    <div class="td">
                      <div class="title">
                        <h4>{{item.name}}</h4>
                      </div>
                      <div class="text">
                        {{item.text}}
                        <span class="arrow">
                          <font-awesome-icon icon="long-arrow-alt-right" />
                        </span>
                      </div>
                    </div>
                    <div class="td date">
                      <span>{{item.date}}</span>
                    </div>
                  </div>
                </div>
              </a>
            </template>
            <template v-else>
              <a href="#" @click="(e) => handleAction(e,item)">
                <div class="table">
                  <div class="tr">
                    <div class="td">
                      <div class="icon">
                        <font-awesome-icon icon="rss" />
                      </div>
                    </div>
                    <div class="td">
                      <div class="title">
                        <h4>{{item.name}}</h4>
                      </div>
                      <div class="text">
                        {{item.text}}
                        <span class="arrow">
                          <font-awesome-icon icon="long-arrow-alt-right" />
                        </span>
                      </div>
                    </div>
                    <div class="td date">
                      <span>{{item.date}}</span>
                    </div>
                  </div>
                </div>
              </a>
            </template>
          </div>
        </div>
        <loader-component v-if="isLoader" />
      </div>
    </div>
</template>

<script>
import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import { readRequest, readAllRequest } from "../../api/notifications";
import { MESSENGER_SHOW } from "../../store/constants";
import LoaderComponent from '../LoaderComponent.vue';

export default {
  components: {
    LoaderComponent
  },
    props: {
        items: {
            type: Array,
            required: true
        },
        hideEvent: {
            type: Function,
            required: true
        }
    },
    setup() {

        const store = useStore();
        const countNotRead = inject('notifyCountNotRead');
        const isLoader = ref(false);

        const handleReadMessage = (item) => {
            item.read = 1;
            readRequest(item.id)
                .then(response => {
                    countNotRead.value = response.data.data.count;
                })
                .catch(err => console.log(err));
        };

      const handleReadAllMessage = (e) => {
        e.preventDefault();
        isLoader.value = true;
        readAllRequest()
          .then(response => {
            isLoader.value = false;
            countNotRead.value = response.data.data.count;
          })
          .catch(err => console.log(err));
      };

        const handleAction = (e,item) => {
            e.preventDefault();
            if(item.action === 1) {
                if(item.messenger.theme>0) {
                    store.commit(MESSENGER_SHOW, {
                        themeId: item.messenger.theme
                    });
                }
            }
        };

        return {
          handleReadMessage,
          handleAction,
          handleReadAllMessage,
          isLoader
        }
    }
}
</script>

<style scoped>

  .notification-outside {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
  }

  .notification {
      position: absolute;
      top: 50px;
      right: 0;
      padding: 10px;
      background-color: #ffffff;
      box-shadow: 0 0 5px rgb(0 0 0 / 20%);
      z-index: 2;
      width: 350px;
      text-align: left;
  }

  .notification .header {
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
    display: flex;
  }

  .notification .header h3 {
    width: 50%;
  }

  .notification .header a {
    width: 50%;
    text-align: right;
    color: #3294ca;
    font-size: 14px;
  }

  .notification .notification-items {
    max-height: 200px;
    overflow-y: scroll;
    padding: 5px;
  }

  .notification .notification-item {
    margin-bottom: 2px;
  }

  .notification .notification-item .td {
    vertical-align: middle;
    color: #878787;
    padding: 5px;
  }

  .notification .notification-item .td:first-child {
    width: 25px;
  }

  .notification .notification-item .icon {
    font-size: 17px;
  }

  .notification .notification-item:hover .table {
    background-color: #f3f3f3;
  }

  .notification .notification-item a {
    text-decoration: none;
  }

  .notification .notification-item.not-read .table {
    background-color: #e1f3fd;
  }

  .notification .notification-item.not-read .td {
    color: #3294ca;
  }

  .notification .notification-item:last-child {
    margin-bottom: 0;
  }

  .notification .notification-item h4 {
    font-weight: normal;
  }

  .notification .notification-item.not-read h4 {
    font-weight: bold;
  }

  .notification .notification-item .td.date {
    font-size: 12px;
    color: #9f9f9f;
    margin-top: 2px;
    vertical-align: top;
  }

  .notification .notification-item .text {
    margin-top: 5px;
  }

</style>
