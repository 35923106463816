<template>
  <div class="form">
    <form @submit="handleSubmitForm">
      <div>
        <input type="text" class="form-control" placeholder="Опишите подробно найденную неточность или Вам вопрос" v-model="input"/>
        <button type="submit">
          <font-awesome-icon :icon="['fab', 'telegram']" />
        </button>
      </div>
      <p v-if="error !== ''" class="error">{{ error }}</p>
    </form>
    <div class="overlay" @click="hideEvent"></div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { createToaster } from '@meforma/vue-toaster';
import { reportModifyRequest } from "../../../../../../../../../api/orders";
export default {
    name: 'FormComponent',
    props: {
        hideEvent: {
            type: Function,
            required: true
        }
    },
    setup(props) {

        const input = ref('');
        const error = ref('');
        const loader = inject('loader');
        const orderId = inject('orderId');
        const reportId = inject('reportId');
        const searchOrders = inject('searchOrders');
        const toaster = createToaster({ position: 'top' });

        const handleSubmitForm = (e) => {
            e.preventDefault();
            loader.value = true;
            error.value = '';
            reportModifyRequest(orderId, reportId, {
                comment: input.value
            })
                .then(response => {
                    loader.value = false;
                    const data = response.data.data;
                    if(data.error) {
                        error.value = data.error['form-comment'][0];
                    } else {
                        props.hideEvent();
                        searchOrders().then(() => {
                            toaster.success('Отчет успешно отправлен на доработку');
                        });
                    }
                })
                .catch(err => console.log(err));
        };

        return {
            input,
            error,
            handleSubmitForm
        }
    }
}
</script>

<style scoped>

  .form {
    margin-top: 10px;
  }

  .form form {
    position: relative;
    z-index: 2;
  }

  .form form>div>button {
    position: absolute;
    top: 3px;
    right: 0px;
    font-size: 26px;
    color: #758a94;
    border: none;
    background-color: transparent;
  }

  .form .error {
    margin-bottom: 0;
  }

  .form .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
  }

</style>
