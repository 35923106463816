import {postRequest, getRequest, putRequest, deleteRequest} from "./axios";
import { MESSENGER_URL } from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies }  = useCookies();

export function getDataRequest(id) {
  return getRequest(`${MESSENGER_URL}/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function addMessageRequest(id, formData) {
  return postRequest(`${MESSENGER_URL}/${id}/messages`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function addFileRequest(id, formData) {
  return postRequest(`${MESSENGER_URL}/${id}/files`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function loadFileRequest(id, fileId) {
  return getRequest(`${MESSENGER_URL}/${id}/files/${fileId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    },
    responseType: 'blob'
  });
}

export function editMessageRequest(id, messageId, formData) {
  return putRequest(`${MESSENGER_URL}/${id}/messages/${messageId}`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function deleteMessageRequest(id, messageId) {
  return deleteRequest(`${MESSENGER_URL}/${id}/messages/${messageId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}
