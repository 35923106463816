import heic2any from "heic2any";

export function getExtensionByName(name) {
  const nameSplit = name.split('.');
  if(nameSplit.length>1) {
    return nameSplit[nameSplit.length-1].toLowerCase();
  } else {
    return null;
  }
}

export function getSrc(file) {
  return URL.createObjectURL(file);
}

export function sizeFormatBytes(bytes, ext = true, decimals = 2) {
  if (bytes === 0) {
    return 0;
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['байт', 'кб', 'мб', 'гб', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if(ext) {
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  } else {
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  }
}

export function createBlobImageUrl(file, type = 'image/jpeg') {
  return URL.createObjectURL(new Blob([file],{type: type}));
}

export const isImage = (extension) => {
  const extensions = ['jpg','png','jpeg','heic','heif'];
  if(extensions.indexOf(extension) > -1) {
    return true;
  }
  return false;
};

export const isMimeTypeImage = (extension) => {
  const extensions = ['image/jpeg','image/jpg','image/png','image/heif','image/heic'];
  if(extensions.indexOf(extension) > -1) {
    return true;
  }
  return false;
};

export const isMimeTypePdf = (extension) => {
  const extensions = ['application/pdf','application/octet-stream'];
  if(extensions.indexOf(extension) > -1) {
    return true;
  }
  return false;
};

export const megabytesToBytes = (megabytes) => {
  return megabytes * 1024 * 1024;
}

export const openFile = (blobFile) => {
  const blob = new Blob([blobFile],{ type: blobFile.type });
  const url = window.URL.createObjectURL(blob, {
    type: blobFile.type
  });
  window.open(url, '_blank');
};

export const downloadFile = (blobFile, fileName) => {
  const blob = new Blob([blobFile],{ type: blobFile.type });
  const url = window.URL.createObjectURL(blob, {
    type: blobFile.type
  });
  console.log(blobFile.type);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.append(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const bytesToMegabytes = (bytes) => {
  return bytes/(1024*1024);
};

export const getShortName = (name, maxLength = 25) => {
  const splitArr = name.split('.');
  let extension;
  if(splitArr.length) {
    extension = splitArr[splitArr.length-1];
  }
  if(name.length > maxLength) {
    name = name.substr(0, maxLength-8) + '... .' + extension;
  }
  return name;
};

export const heicToBlobImage = async (file, mimeType = "image/jpeg", quality = 0.5) => {
  return await new Promise(async (resolve) => {
    const conversionResult = await heic2any({
      blob: file,
      toType: mimeType,
      quality: quality, // cuts the quality and size by half
    });
    resolve(conversionResult);
  });
}

export const resizeImage = async (imageFile, maxSideSize = 4000) => {

  return await new Promise((resolve) => {

    let reader = new FileReader();

    reader.onload = (e) => {

      const img = document.createElement("img");
      // Dynamically create a canvas element
      const canvas = document.createElement("canvas");

      img.onload = async function () {

        let width = img.width;
        let height = img.height;
        let result = { oldFile: {}, newFile: {} };
        result.oldFile = { width: width, height: height, size: imageFile.size };

        if (width > height || width === height) {
          let wrh = height / width;
          if (width > maxSideSize) {
            width = maxSideSize;
            height = wrh * maxSideSize;
          }
          if (height > maxSideSize) {
            let wrh = width / height;
            width = wrh * maxSideSize;
            height = maxSideSize;
          }
        } else if (height > width) {
          let wrh = width / height;
          if (height > maxSideSize) {
            width = wrh * maxSideSize;
            height = maxSideSize;
          }
          if (width > maxSideSize) {
            let wrh = height / width;
            width = maxSideSize;
            height = wrh * maxSideSize;
          }
        }

        result.newFile = { width: width, height: height }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(function (blob) {
          result.newFile.blob = blob;
          resolve(result);
        }, imageFile.type);
      }

      img.src = e.target.result;

    };

    reader.readAsDataURL(imageFile);

  });
};
