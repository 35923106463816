<template>
  <section v-html="content"></section>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useHead } from '@unhead/vue';
import { getIndependenceRequest } from "../api/site";
export default {
    name: "IndependenceView",
    setup() {

        const content = ref('');

        onMounted(() => {

            useHead({
                title: 'НАСЛЕДСТВО.ОНЛАЙН - сервис, соблюдающий принцип независимости нотариуса',
                meta: [
                    { name: 'keywords', content: '' },
                    { name: 'description', content: ''  },
                ],
            });

            getIndependenceRequest().then(response => {
                if(response.data) {
                    content.value = response.data.data;
                }
            }).catch(err => console.log(err));

        });

        return {
            content
        }
    }
}
</script>

<style scoped>


</style>
