<template>
  <div class="step-2">
    <h1>Заполните сведения о наследстве</h1>
    <div class="group-block">
      <search-data-component
        :format-date-handle="formatDateHandle"
        :submit-handle="submitHandle"
        :form="evalForm"
      />
      <div v-if="notaryForm.isFound !== null" class="result">
        <div class="row">
          <div class="col-sm-12">
            <notary-list-component
              v-if="notaries.length"
              :notary-form="notaryForm"
              :notaries="notaries"
              :clear-notary-handler="clearNotaryHandler"
            />
            <search-local-component
              v-else
              :is-find-request-time-out="isFindRequestTimeOut"
              :form="notaryForm"
            />
          </div>
        </div>
      </div>
    </div>
    <next-button-component
      :form-validate-handle="formValidateHandle"
      :prev-step-handle="prevStepHandle"
      :next-step-handle="nextStepHandle"
    />
  </div>
</template>

<script>
import { ref, toRef } from 'vue';
import { useStore } from 'vuex';
import { findRequest } from "../../../../../api/notaries";
import { parseDateFormat } from "../../../../../helpers/helper";
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER } from "@/store/constants";
import SearchDataComponent from "./SearchDataComponent.vue";
import NextButtonComponent from "./NextButtonComponent.vue";
import NotaryListComponent from "./NotaryListComponent.vue";
import SearchLocalComponent from "./SearchLocalComponent.vue";

export default {
  name: 'Step2Component',
  components: {
    SearchDataComponent,
    NextButtonComponent,
    NotaryListComponent,
    SearchLocalComponent
  },
  props: {
    evalForm: {
      type: Object,
      required: true
    },
    nextStepHandle: {
      type: Function,
      required: true
    },
    prevStepHandle: {
      type: Function,
      required: true
    },
    notaries: {
      type: Array
    },
    notaryForm: {
      type: Object,
      required: true
    },
    clearNotaryHandler: {
      type: Function,
      required: true
    }
  },
  setup(props, context) {

    const store = useStore();
    const isFindRequestTimeOut = ref(false);
    const notaryForm = toRef(props, 'notaryForm');
    const evalForm = toRef(props, 'evalForm');

    const formatDateHandle = (e) => {
      e.target.value = parseDateFormat(e.target.value);
    };

    const submitHandle = () => {
      setTimeout(function () {
        if(formValidateHandle()) {
          isFindRequestTimeOut.value = false;
          context.emit('update:notaries', []);
          notaryForm.value.isFound = null;
          const date = new Date(evalForm.value.date.value);
          store.commit(DEFAULT_SHOW_LOADER);
          findRequest({
            name: evalForm.value.name.value,
            date: date.getTime()
          }).then(response => {
            store.commit(DEFAULT_HIDE_LOADER);
            const data = response.data.data;
            if(data) {
              if(data.items.length) {
                notaryForm.value.isFound = true;
                context.emit('update:notaries', data.items);
              } else {
                notaryForm.value.isFound = false;
              }
              notaryForm.value.log = data.proccess;
            }
          }).catch(err => {
            console.log(err);
            isFindRequestTimeOut.value = true;
            store.commit(DEFAULT_HIDE_LOADER);
            notaryForm.value.isFound = false;
          })
        }
      },0);
    };

    const formValidateHandle = (checkAll = false) => {

      let error = false;
      const date = (new Date()).getTime();

      if(evalForm.value.date.value === '' || evalForm.value.date.value === null) {
        evalForm.value.date.error = 'Заполните дату смерти наследодателя';
        error = true;
      } else {
        if((new Date(evalForm.value.date.value)).getTime() >= date) {
          evalForm.value.date.error = 'Дата не может быть больше текущей';
          error = true;
        } else {
          evalForm.value.date.error = '';
        }
      }

      if(evalForm.value.name.value === '' || evalForm.value.name.value === null) {
        evalForm.value.name.error = 'Заполните ФИО наследодателя (по свидетельству о смерти)';
        error = true;
      } else {
        evalForm.value.name.error = '';
      }

      if (checkAll) {
        if(notaryForm.value.isFound === null || !notaryForm.value.data) {
          error = true;
        }
      }

      if(error) {
        return false;
      }
      return true;
    };

    return {
      isFindRequestTimeOut,
      formatDateHandle,
      submitHandle,
      formValidateHandle,
    }
  }
}
</script>

<style scoped>

.step-2 {
  max-width: 600px;
  margin: 0 auto;
}

.group-block {
  border: 1px solid #c7c7c7;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
}

.result {
  margin-top: 15px;
}

</style>
