<template>
  <div :class="getClass(message)" @mouseenter="handleShowMenuButton" @mouseleave="handleHideMenuButton" ref="messageRef">
    <reply-content-component v-if="message.reply" :message="message.reply"/>
    <file-content-component v-if="message.type === 1" :message="message"/>
    <text-content-component v-else :message="message"/>
    <menu-component
      v-if="isShowMenuButton"
      :message="message"
      :is-show-list="isShowMenuList"
      :show-event="handleShowMenuList"
      :hide-event="handleHideMenuList"
    />
    <loader-component v-if="message.loader"/>
  </div>
</template>

<script>
import { ref, watchEffect, provide, inject } from 'vue';
import { deleteMessageRequest } from "../../../../api/messenger";
import LoaderComponent from "../../../LoaderComponent";
import ReplyContentComponent from "./replyContent/ReplyContentComponent";
import TextContentComponent from "./textContent/TextContentComponent";
import FileContentComponent from "./fileContent/FileContentComponent";
import MenuComponent from "./menu/MenuComponent";
export default {
    name: "MessageComponent",
    components: {
        LoaderComponent,
        ReplyContentComponent,
        TextContentComponent,
        FileContentComponent,
        MenuComponent
    },
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const isShowMenuButton = ref(false);
        const isShowMenuList = ref(false);
        const messageRef = ref(null);
        const currentMessage = inject('currentMessage');
        const formFields = inject('formFields');
        const messengerId = inject('messengerId');

        const getClass = (message) => {
            let className = ['message'];
            if(!message.isAuthor) {
                className.push('from');
                className.push('read-' + message.read);
            } else {
                className.push('to');
            }
            return className;
        };

        const handleShowMenuButton = () => {
            isShowMenuButton.value = true;
        };

        const handleHideMenuButton = () => {
            if(!isShowMenuList.value) {
                isShowMenuButton.value = false;
            } else {
                isShowMenuButton.value = true
            }
        };

        const handleShowMenuList = (e) => {
            e.preventDefault();
            isShowMenuList.value = true;
        };

        const handleHideMenuList = () => {
            isShowMenuList.value = false;
            isShowMenuButton.value = false;
        };

        const handleSelectMessage = (e, message, action) => {
            e.preventDefault();
            currentMessage.value.item = message;
            currentMessage.value.action = action;
            if(action === 'edit') {
                formFields.value.id = message.id;
                formFields.value.text = message.text;
            } else {
                formFields.value.reply = message;
            }
            isShowMenuList.value = false;
            isShowMenuButton.value = false;
        };

        const handleDeleteMessage = (e, message) => {
            e.preventDefault();
            isShowMenuList.value = false;
            isShowMenuButton.value = false;
            message.loader = true;
            deleteMessageRequest(messengerId.value, message.id)
                .then(response => {
                    message.loader = false;
                    if(response.data.data) {
                        message.deleted = 1;
                    }
                })
                .catch(err => console.log(err));
        };

        watchEffect(() => {
            if(!props.message.id) {
                props.message.loader = true;
            } else {
                props.message.loader = false;
            }
        });

        provide('messageRef', messageRef);
        provide('handleSelectMessage', handleSelectMessage);
        provide('handleDeleteMessage', handleDeleteMessage);

        return {
            getClass,
            isShowMenuButton,
            handleShowMenuButton,
            handleHideMenuButton,
            handleShowMenuList,
            handleHideMenuList,
            isShowMenuList,
            messageRef
        }
    }
}
</script>

<style scoped>

  .message {
    color: #FFFFFF;
    font-size: 14px;
    width: max-content;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    max-width: 100%;
    position: relative;
    padding: 8px;
    margin-bottom: 5px;
    min-width: 50px;
  }

  .message.from {
    background-color: #ecf0f3;
  }

  .message.to {
    background-color: #cbecff;
  }

</style>
