<template>
  <div class="file">
    <a href="#" :title="file.title" @click="handleGetFile">
      <font-awesome-icon v-if="file.type === 'pdf'" icon="file-pdf" />
      <font-awesome-icon v-if="file.type === 'zip'" icon="file-archive" />
      <font-awesome-icon v-if="file.type === 'sig'" icon="pen-square" />
      {{ file.name }}
      <loader-component v-if="loader"/>
    </a>
    <template v-if="!isLast">;</template>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { createToaster } from '@meforma/vue-toaster';
import { getFileRequest, getZipRequest } from "../../../../../../../../../api/orders";
import { downloadFile } from "../../../../../../../../../helpers/file";
import LoaderComponent from "../../../../../../../../../components/LoaderComponent";
export default {
    name: "FileComponent",
    components: {
        LoaderComponent
    },
    props: {
        file: {
            type: Object,
            required: true
        },
        folderName: {
            type: String,
            required: true
        },
        isLast: {
            type: Boolean,
            required: true
        }
    },
    setup(props) {

        const orderId = inject('orderId');
        const reportId = inject('reportId');
        const toaster = createToaster({ position: 'top' });
        const loader = ref(false);

        const handleGetFile = (e) => {
            e.preventDefault();
            if(props.file.type === 'zip') {
                handleGetZip();
            } else {
                loader.value = true;
                getFileRequest(orderId, reportId, props.file.id)
                    .then(response => {
                        loader.value = false;
                        downloadFile(response.data, props.file.sourceName);
                    })
                    .catch(err => console.log(err));
            }
        };

        const handleGetZip = () => {
            loader.value = true;
            toaster.warning('Zip архив формируется. Это может занять некоторое время...');
            getZipRequest(orderId, reportId)
                .then(response => {
                    loader.value = false;
                    downloadFile(response.data, props.folderName)
                })
                .catch(err => console.log(err));
        };

        return {
            handleGetFile,
            loader
        }
    }
}
</script>

<style scoped>

  .file {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    margin-bottom: 8px;
  }

  .file>a {
    color: #555555 !important;
    border-bottom: 1px dashed #555555;
    padding-bottom: 3px;
    text-decoration: none !important;
  }

  .file>a:hover {
    border-bottom: 1px solid transparent;
  }

  .file:last-child {
    margin-right: 0;
  }

  @media (max-width: 992px) {

    .file {
      display: block;
      margin-top: 5px;
    }

  }

</style>
