<template>
  <layout-legacy>
    <breadcrumb-component/>
    <section class="content">
      <section>
        <pre-orders-component v-if="preOrders.items.length"/>
        <orders-component :filter="orderFilter" :is-show-filter="isShowOrderFilter" />
      </section>
    </section>
  </layout-legacy>
</template>

<script>
import { ref, provide, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useSeoMeta } from '@unhead/vue';
import { getAllRequest as searchPreOrdersRequest } from "../../api/preOrders";
import { searchRequest as searchOrdersRequest } from "../../api/orders";
import { BREADCRUMBS_SET_ITEM } from "../../store/constants";
import LayoutLegacy from "../../layouts/LayoutLegacy";
import BreadcrumbComponent from "../../components/BreadcrumbComponent";
import PreOrdersComponent from "./preOrders/PreOrders";
import OrdersComponent from "./orders/Orders";

export default {
    name: 'OrdersView',
    components: {
        LayoutLegacy,
        BreadcrumbComponent,
        PreOrdersComponent,
        OrdersComponent
    },
    setup() {

        const store = useStore();
        const legalId = store.state.legalId;
        const preOrders = ref({ items: [], pages: [] });
        const orders = ref({items: [], pages: []});
        const ordersLoader = ref(false);
        const archive = ref({items: [], pages: []});
        const archiveLoader = ref(false);
        const orderFilter = ref({
          value: '',
          year: 0,
          onlyNotConfirm: false
        });

        const searchPreOrders = (page = 1) => {
            return searchPreOrdersRequest({
                legalId: legalId,
                page: page
            })
                .then(response => {
                    const data = response.data.data;
                    if(data) {
                        preOrders.value.items = data.items;
                        preOrders.value.pages = data.pages;
                    }
                })
                .catch(err => console.log(err));
        };

        const isShowOrderFilter = () => {
          return store.state.auth.user && store.state.auth.user.countOrders > 10;
        }

        const searchOrders = (page = 1) => {
            ordersLoader.value = true;
            return searchOrdersRequest({
              legalId: legalId,
              page: page,
              value: orderFilter.value.value,
              year: orderFilter.value.year,
              onlyNotConfirm: Number(orderFilter.value.onlyNotConfirm)
            })
                .then(response => {
                    ordersLoader.value = false;
                    const data = response.data.data;
                    if(data) {
                        orders.value.items = data.items;
                        orders.value.pages = data.pages;
                    }
                })
                .catch(err => console.log(err));
        };

        onMounted(() => {

            useSeoMeta({
                title: 'Заказы'
            });

            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: 'Заказы', path: '/orders' }
            });

            if (isShowOrderFilter()) {
              orderFilter.value.year = (new Date()).getFullYear()
            }

            searchPreOrders();
            searchOrders();
        });

        provide('preOrders', preOrders);
        provide('orders', orders);
        provide('archive', archive);
        provide('searchPreOrders', searchPreOrders);
        provide('searchOrders', searchOrders);
        provide('ordersLoader', ordersLoader);
        provide('archiveLoader', archiveLoader);

        return {
          preOrders,
          orders,
          archive,
          orderFilter,
          isShowOrderFilter
        }
    }
}
</script>

<style scoped>

  .beta-text {
    font-size: 14px;
    color: red;
    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px
  }

</style>
