<template>
  <div class="form">
    <form @submit="handleSubmit">
      <div>
        <dadata-suggestions
          v-model="field.address"
          type="ADDRESS"
          class="form-control"
          placeholder="Укажите адрес доставки"
          field-value="unrestricted_value"
        />
        <button type="submit">
          <font-awesome-icon icon="arrow-right" />
        </button>
      </div>
      <p v-if="field.error" class="error">{{ field.error }}</p>
    </form>
    <div class="overlay" @click="hideEvent"></div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { setDeliveryAddressRequest } from "../../../../../../../../api/orders";

export default {
    name: "FormComponent",
    props: {
        field: {
            type: Object,
            required: true
        },
        hideEvent: {
            type: Function,
            required: true
        }
    },
    setup(props) {

        const loader = inject('loader');
        const orderId = inject('orderId');
        const searchOrders = inject('searchOrders');

        const handleSubmit = (e) => {
            e.preventDefault();
            loader.value = true;
            props.field.error = '';
            setDeliveryAddressRequest(orderId, {
                type: 2,
                value: props.field.address
            })
                .then(response => {
                    const data = response.data.data;
                    if(data.error) {
                        loader.value = false;
                        props.field.error = data.error['setdeliveryaddressform-value'][0];
                    } else {
                        props.hideEvent();
                        searchOrders().then(() => {
                            loader.value = false;
                        });
                    }
                })
                .catch(err => console.log(err));
        };

        return {
            handleSubmit
        }
    }
}
</script>

<style scoped>

  .form {
    margin-top: 10px;
  }

  .form>form {
    position: relative;
    z-index: 2;
  }

  .form>form>div {
    position: relative;
  }

  .form .suggestions-wrapper {
    position: absolute;
    z-index: 2;
    width: 100%;
    text-align: left;
  }

  .form>form>div>button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #3294ca;
    color: #FFFFFF;
    font-size: 14px;
    padding: 10px 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #005f96;
    height: 100%;
  }

  .form .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

</style>
