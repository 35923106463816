<template>
  <services-component
    :services="services"
    :price="price"
    :paid="paid"
    :is-with-pre-pay="isWithPrePay"
    :pre-pay-sum="prePaySum"
    :delivery="delivery"
    :all-report-confirmed="allReportConfirmed"
    :is-complete-all-report-field="isCompleteAllReportField"
  />
  <status-component :status="status"/>
  <notary-component
    v-if="notary && reportDigitalType === 2"
    :notary="notary"
    :all-report-signed="allReportSigned"
    :is-full-payed="paid>=price"
    :all-report-complete="allReportComplete"
    :all-report-confirmed="allReportConfirmed"
    :client-name="clientName"
  />
  <delivery-component
    v-if="reportPaperType === 2 || reportPaperType === 3"
    :delivery="delivery"
    :status="status"
    :all-report-confirmed="allReportConfirmed"
    :exist-report-on-print="existReportOnPrint"
    :is-all-report-printed="isAllReportPrinted"
    :is-all-report-sent-mail-russia="isAllReportSentMailRussia"
    :is-all-report-sent-delivery="isAllReportSentDelivery"
    :is-full-payed="paid>=price"
  />
  <payment-component
    :status="status"
    :price="price"
    :paid="paid"
    :discount="discount"
    :is-with-pre-pay="isWithPrePay"
    :pre-pay-sum="prePaySum"
  />
</template>

<script>
import { inject } from 'vue';
import ServicesComponent from "./services/ServicesComponent";
import StatusComponent from "./status/StatusComponent";
import NotaryComponent from "./notary/NotaryComponent";
import DeliveryComponent from "./delivery/DeliveryComponent";
import PaymentComponent from "./payment/PaymentComponent";
export default {
    name: "BodyComponent",
    components: {
        ServicesComponent,
        StatusComponent,
        NotaryComponent,
        DeliveryComponent,
        PaymentComponent
    },
    props: {
        services: {
            type: Array,
            required: true
        },
        status: {
            type: Object,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        paid: {
            type: Number,
            required: true
        },
        discount: {
            type: Number,
            required: true
        },
        isWithPrePay: {
            type: Boolean,
            required: true
        },
        allReportSigned: {
            type: Boolean,
            required: true
        },
        prePaySum: {
            type: Number,
            required: true
        },
        delivery: {
            type: Object,
            required: true
        },
        notary: {
            type: Object
        },
        allReportConfirmed: {
            type: Boolean,
            required: true
        },
        allReportComplete: {
            type: Boolean,
            required: true
        },
        existReportOnPrint: {
            type: Boolean,
            required: true
        },
        isAllReportPrinted: {
            type: Boolean,
            required: true
        },
        isAllReportSentMailRussia: {
            type: Boolean,
            required: true
        },
        isAllReportSentDelivery: {
            type: Boolean,
            required: true
        },
        isCompleteAllReportField: {
            type: Boolean,
            required: true
        },
        clientName: {
          type: String,
          required: true
        }
    },
    setup() {

        const reportDigitalType = inject('reportDigitalType');
        const reportPaperType = inject('reportPaperType');

        return {
            reportDigitalType,
            reportPaperType
        }
    }
}
</script>

<style scoped>

</style>
