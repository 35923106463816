<template>
    <div>
      <loader-component v-if="loader" />
    </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER, AUTH_SET_USER } from "../../store/constants";
import { loginByManagerToken } from "../../api/auth";
import { useCookies } from 'vue3-cookies';
import LoaderComponent from "../../components/LoaderComponent";

export default {
    name: "Manager",
    components: {
        LoaderComponent
    },
    setup() {

        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const { cookies } = useCookies();
        const token = route.query.token;

        onMounted(() => {
            store.commit(DEFAULT_SHOW_LOADER);
            loginByManagerToken(token)
                .then(response => {
                    if(response.data.data.token) {
                        store.commit(DEFAULT_HIDE_LOADER);
                        cookies.set('token', response.data.data.token);
                        store.commit(AUTH_SET_USER, response.data.data.user);
                        router.push('/orders');
                    }
                })
                .catch(err => console.log(err));
        });

        return {
            loader: computed(() => store.state.loader),
        }
    }
}
</script>

<style scoped>

</style>
