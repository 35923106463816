<template>
  <section class="payment">
    <span class="title">Стоимость и оплата</span>
    <div class="info">
      <span>Общая стоимость: <b>{{ price }} ₽</b><template v-if="discount"> (скидка {{ discount }}%)</template>.</span>
      <template v-if="paid<price">
        <span v-if="paid">Уже оплачено {{ paid }} ₽.</span>
        <span v-if="status.id === 2">Оплата по готовности (сейчас не требуется).</span>
        <span v-else-if="status.id === 1">
          Требуется предоплата {{ prePaySum }} ₽
        </span>
        <span v-else-if="status.id === 4">
          Требуется оплата {{ price-paid }} ₽
        </span>
      </template>
      <template v-else>
        <span>Заказ полностью оплачен</span>
      </template>
    </div>
    <div v-if="status.id === 1 || status.id === 4" class="action">
      <button v-if="status.id === 1" type="button" @click="handlePayment">Оплатить {{ prePaySum }} руб</button>
      <button v-else-if="status.id === 4" type="button" @click="handlePayment">Оплатить {{ price-paid }} руб</button>
    </div>
  </section>
</template>

<script>
import { inject } from 'vue';
import { getPaymentLinkRequest } from "../../../../../../api/orders";

export default {
    name: "PaymentComponent",
    props: {
        status: {
            type: Object,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        paid: {
            type: Number,
            required: true
        },
        discount: {
            type: Number,
            required: true
        },
        isWithPrePay: {
            type: Boolean,
            required: true
        },
        prePaySum: {
            type: Number,
            required: true
        }
    },
    setup() {

        const orderId = inject('orderId');
        const loader = inject('loader');

        const handlePayment = () => {
            loader.value = true;
            getPaymentLinkRequest(orderId)
                .then(response => {
                    loader.value = false;
                    window.location.href = response.data.data;
                })
                .catch(err => console.log(err));
        };

        return {
            handlePayment
        }
    }
}
</script>

<style scoped>

  .payment {
    border-top: 1px dotted;
    border-color: gray;
    font-family: 'Roboto';
    color: #626262;
    margin: 20px 0;
    padding: 15px 20px 0 20px;
    position: relative;
  }

  .payment .title {
    position: absolute;
    top: -8px;
    background: white;
    left: 15px;
    padding: 0 5px;
    color: #3294ca;
  }

  .payment .info {
    font-size: 18px;
  }

  .payment .info>span {
    margin-right: 5px;
  }

  .payment .info>span:last-child {
    font-weight: 500;
  }

  .payment .action {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    width: 100%;
  }

  .payment .action button {
    width: 200px;
    display: inline-block;
    background: #ff9800;
    color: #ffffff;
    margin-top: 15px;
    padding: 3px 5px;
    border-radius: 5px;
    border: none;
  }

</style>
