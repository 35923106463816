import { OBJECTS_URL } from "./constants";
import { getRequest} from "./axios";

export function getAllRequest() {
  return getRequest(`${OBJECTS_URL}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}
