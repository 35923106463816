<template>
  <div class="loader" :style="['position: ' + position + ';', 'color:' + color + ';']">
    <font-awesome-icon :icon="['fas','spinner']" />
    <p class="text" v-if="text">{{ text }}</p>
  </div>
</template>

<script>
export default {
    name: "LoaderComponent",
    props: {
      position: {
          type: String,
          required: false,
          default: 'absolute'
      },
      color: {
          type: String,
          required: false,
          default: '#959595'
      },
      text: {
        type: String,
        required: false,
        default: null
      }
    }
}
</script>

<style scoped>

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(241,241,241,.7);
    top: 0;
    left: 0;
    z-index: 3;
  }

  .loader>svg {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 25px;
    margin-top: -12px;
    margin-left: -16px;
  }

  .loader>.text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    margin-top: 15px;
    font-size: 17px;
    color: #525252;
  }

</style>
