<template>
  <div class="email">
    <h2>E-mail адрес</h2>
    <form @submit="handleSubmit">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="E-mail" v-model="form.email" />
        <p class="error" v-if="form.error">{{form.errorText}}</p>
      </div>
      <div class="form-group">
        <button class="btn btn-custom" type="submit">Сохранить</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { updateEmailRequest } from "../../../../api/user";
import { createToaster } from '@meforma/vue-toaster';
import { AUTH_SET_USER } from "../../../../store/constants";
import { useStore } from 'vuex';

export default {
    name: 'EmailFormComponent',
    props: {
        email: {
            type: String,
            required: true
        }
    },
    setup({ email }) {

        const store = useStore();
        const user = store.state.auth.user;
        const toaster = createToaster({position: 'top'});
        const form = ref({
            email: email,
            error: false,
            errorText: ''
        });

        const handleSubmit = (e) => {
            e.preventDefault();
            form.value.error = false;
            form.value.errorText = '';
            updateEmailRequest({
              email: form.value.email
            }).then(response => {
                const data = response.data.data;
                if(data.error) {
                    form.value.error = true;
                    form.value.errorText = data.error['form-email'][0];
                } else {
                    store.commit(AUTH_SET_USER,{
                        ...user, email: form.value.email
                    });
                    toaster.success('Успешно сохранено');
                }
            }).catch(err => console.log(err));
        };

        return {
            form,
            handleSubmit
        }
    }
}
</script>

<style scoped>

</style>
