<template>
  <div class="step-1">
    <h1>Загрузка документов</h1>
    <div class="content">
      <div class="services">
        <div v-for="(object, objectKey) in objects" :key="objectKey" class="row">
          <div class="col-sm-2 left">
            <div class="image">
              <img :src="object.image" />
            </div>
            <div class="name">
              <span>{{objectKey+1}}. {{ object.name }}</span>
            </div>
          </div>
          <div class="col-sm-10 right">
            <div class="table">
              <div class="tr">
                <div class="td">
                  <template v-if="object.files.length">
                    <div class="files-count">
                      <b>Добавлено:</b> {{ object.files.length }} файл(а)
                      <span v-if="object.prompt" class="prompt-modal">
                        <span>(Что загружать?)<span v-html="object.prompt"></span></span>
                      </span>
                    </div>
                  </template>
                  <template v-else-if="object.prompt">
                    <div class="prompt">
                      <div v-html="object.prompt"></div>
                    </div>
                    <div class="prompt-mobile">
                      <div class="prompt-mobile-title">Варианты документов:</div>
                      <div v-html="object.promptMobile"></div>
                    </div>
                    <div v-if="objectKey === 0" class="info-text">
                      Фото документа, дающего право на <router-link to="/information/skidki">скидку</router-link>, вы можете подгрузить здесь, с документами первого объекта оценки.
                    </div>
                  </template>
                  <template v-if="object.files.length">
                    <desktop-files-component
                      :object-index="objectKey"
                      :files="object.files"
                      :delete-event="handleDeleteFile"
                    />
                    <mobile-files-component
                      :object-index="objectKey"
                      :files="object.files"
                      :delete-event="handleDeleteFile"
                    />
                  </template>
                  <div v-if="object.filesError" class="service-error">
                    <p>{{ object.filesError }}</p>
                  </div>
                  <div class="upload-action">
                    <div class="upload-btn">
                      <a href="#" onclick="return false;">Добавить документ</a>
                      <input type="file" multiple="" @change="(event) => handleOnChangeFile(event, objectKey)">
                    </div>
                    <span class="upload-btn-info"><i>ПАСПОРТ ИЛИ СВ-ВО О СМЕРТИ ПОДГРУЖАТЬ НЕ НУЖНЫ!</i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-nav">
      <div class="row">
        <div class="col-sm-6">
          <router-link to="/cart">Вернуться назад</router-link>
        </div>
        <div class="col-sm-6">
          <a v-if="existUploadFiles()" @click="nextStepHandle" href="#">Далее</a>
          <a v-else @click="handleCheckFiles" href="#" class="disabled">Далее</a>
        </div>
      </div>
    </div>
    <loader-component v-if="loader.isShow" :text="loader.text"/>
  </div>
</template>

<script>
import { toRef, ref } from 'vue';
import {
  getExtensionByName,
  heicToBlobImage,
  isImage,
  isMimeTypePdf,
  megabytesToBytes,
  sizeFormatBytes
} from "../../../../../helpers/file";
import { resizeImage } from "../../../../../helpers/file";
import DesktopFilesComponent from "./DesktopFilesComponent.vue";
import MobileFilesComponent from "./MobileFilesComponent";
import LoaderComponent from "../../../../../components/LoaderComponent.vue";

export default {
    components: {
      DesktopFilesComponent,
      MobileFilesComponent,
      LoaderComponent
    },
  props: {
    objects: {
      type: Array,
      required: true
    },
    fileAllowExtensions: {
      type: Array,
      required: true
    },
    fileAllowSize: {
      type: Number,
      required: true
    },
    nextStepHandle: {
      type: Function,
      required: true
    }
  },
  setup(props) {

    const objects = toRef(props, 'objects');
    const fileAllowExtensions = toRef(props, 'fileAllowExtensions');
    const fileAllowSize = toRef(props, 'fileAllowSize');
    const loader = ref({
      isShow: false,
      text: ''
    });

    const getFilesSize = () => {
      let size = 0;
      objects.value.forEach(object => {
          object.files.forEach(file => {
              size += file.size;
          });
      });
      return size;
    };

    const handleOnChangeFile = async (e, objectKey) => {

        const uploadFiles = e.target.files || e.dataTransfer.files;

        if (!uploadFiles.length || uploadFiles.length === 0) {
            return;
        }

        const object = objects.value[objectKey];

        loader.value.isShow = true;

        for(let i=0; i<uploadFiles.length; i++) {

            loader.value.text = `Обработка файлов...${i} из ${uploadFiles.length}`;

            const uploadFile = uploadFiles[i];
            let file = {};
            file.name = uploadFile.name;

            if(!uploadFile.size) {
                object.filesError = 'Ошибка закачки файла ' + uploadFile.name + ', повторите';
                loader.value.isShow = false;
            } else {

                const extension = getExtensionByName(uploadFile.name);

                object.filesError = '';

                if (fileAllowExtensions.value.indexOf(extension)<0) {
                    object.filesError = 'Неверный формат файла ' + uploadFile.name + '. Допустимые - ' + fileAllowExtensions.value.join(', ');
                    loader.value.isShow = false;
                    return;
                }

                if (isMimeTypePdf(uploadFile.type) && uploadFile.size > megabytesToBytes(20)) {
                    object.filesError = 'Файл ' + uploadFile.name + ' превышает допустимый размер в 20мб';
                    loader.value.isShow = false;
                    return;
                }

                if((getFilesSize()+uploadFile.size)>fileAllowSize) {
                    object.filesError = 'Превышен свободный лимит для загрузки файлов ('+sizeFormatBytes(fileAllowSize)+').';
                    loader.value.isShow = false;
                    return;
                }

                const fileInfo = { name: uploadFile.name, size: uploadFile.size, width: 0, height: 0 };

                if(isImage(extension)) {

                  let f = uploadFile;

                  if (extension === 'heic' || extension === 'heif') {
                    f = await heicToBlobImage(f);
                  }

                  const resizedImage = await resizeImage(f);

                  fileInfo.width = resizedImage.oldFile.width;
                  fileInfo.height = resizedImage.oldFile.height;

                  file.data = resizedImage.newFile.blob;

                } else {
                  file.data = uploadFile;
                }

                object.files.push(file);
                object.filesInfo.push(fileInfo);
            }
        }

        loader.value.isShow = false;
    };

    const handleDeleteFile = (objectKey, fileKey) => {
        const object = objects.value[objectKey];
        object.files = object.files.filter((file, index) => index !== fileKey);
        object.filesInfo = object.filesInfo.filter((item, index) => index !== fileKey);
    };

    const existUploadFiles = () => {
        const items = objects.value;
        for (let i=0; i<items.length; i++) {
            const item = items[i];
            if(item.files.length===0) {
                return false;
            }
        }
        return true;
    };

    const checkServiceFiles = () => {
        for (let i=0; i<objects.value.length; i++) {
            const object = objects.value[i];
            object.filesError = '';
            if(object.files.length===0) {
                object.filesError = 'Необходимо загрузить хотя бы один документ';
            }
        }
        return true;
    };

    const handleCheckFiles = (e) => {
        e.preventDefault();
        checkServiceFiles();
    };

    return {
      objects,
      handleDeleteFile,
      handleOnChangeFile,
      getExtensionByName,
      existUploadFiles,
      handleCheckFiles,
      loader
    }
  }
}
</script>

<style scoped>

  /*step1*/

  .services>.row {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #cccccc;
  }

  .services>.row:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .services .image {
    margin-bottom: 5px;
  }

  .services .name {
    text-align: left;
    margin-bottom: 5px;
  }

  .services .service-error {
    margin: 10px 0;
  }

  .services .service-error>p {
    color: #ff4646;
    background-color: #ffdada;
    border: 1px solid #ff9898;
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
  }

  .services .upload-btn {
    position: relative;
    overflow: hidden;
    background-color: #3294ca;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    border: 1px solid #307399;
    padding: 5px 10px;
    margin-top: 5px;
    border-radius: 5px;
  }

  .services .upload-btn a {
    font-size: 18px;
    color: #ffffff;
  }

  .services .upload-btn input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .services .upload-btn-info {
    margin-left: 10px;
    color: #000;
    font-weight: 500;
    text-decoration: underline;
  }

  .services .files-count {
    color: #3294ca;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .services .prompt {
    margin-bottom: 10px;
  }

  .services .prompt :deep(li) {
    padding-bottom: 3px;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .services .prompt li {
    font-size: 18px;
    padding-bottom: 3px;
    line-height: 1.5em;
  }

  .services .prompt-modal {
    margin-bottom: 10px;
  }

  .services .prompt-modal>span {
    position: relative;
    display: inline-block;
    color: #3294ca;
    font-size: 18px;
  }

  .services .prompt-modal>span>span {
    border: 1px solid #cdcdcd;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    color: #606060;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    position: absolute;
    width: max-content;
    top: 17px;
    left: 0;
    display: none;
    z-index: 3;
  }

  .services .prompt-modal:hover>span>span {
    display: block;
  }

  .services .prompt-modal>span>span p {
    line-height: 19px;
  }

  .services .col-sm-10 .table {
    height: 100%;
  }

  .services .col-sm-10 .td {
    vertical-align: middle;
  }

  /*step-nav*/

  .step-nav {
    margin-top: 20px;
  }

  .step-nav .row>.col-sm-6:first-child {
    text-align: left;
  }

  .step-nav .row>.col-sm-6:last-child {
    text-align: right;
  }

  .step-nav a {
    background-color: #ffffff;
    color: #1b1e21;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #c7c7c7;
    display: inline-block;
    text-decoration: none;
  }

  .step-nav .btn-send-form {
    background-color: #3194ca;
    color: #ffffff;
    border: none;
  }

  .step-nav a.disabled {
    background-color: #d3d3d3;
  }

  .info-text {
    padding-bottom: 3px;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .info-text a {
    color: #3294ca;
    text-decoration: underline;
  }

  .info-text a:hover {
    text-decoration: none;
  }

  .prompt-mobile {
    display: none;
    margin-bottom: 10px;
  }

  .prompt-mobile-title {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {

    .step-nav .col-sm-6 {
      width: 50%;
    }

    .services .image,
    .services .name {
      margin-bottom: 10px;
    }

    .services .name {
      font-size: 17px;
    }

    .files-count {
      display: none;
    }

    .prompt {
      display: none;
    }

    .prompt-mobile {
      display: block;
    }

    .info-text {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .services .upload-action {
      text-align: center;
    }

    .services .upload-btn-info {
      display: block;
      margin: 5px 0;
    }

  }

</style>
