export const BASE_URL = `${process.env.VUE_APP_API_URL}`;

export const USER_URL = '/user';

export const AUTH_SEND_CODE_URL = '/auth/send-code';
export const AUTH_SEND_VERIFY_CODE_URL = '/auth/verify-code';
export const AUTH_REFRESH_TOKEN_URL = '/auth/refresh-token';
export const AUTH_LOGOUT_URL = '/auth/logout';
export const AUTH_LOGIN_BY_MANAGER_TOKEN_URL = '/auth/login-by-manager-token';

export const OBJECTS_URL = '/objects';

export const PRE_ORDERS_URL = '/pre-orders';
export const ORDERS_URL = '/orders';
export const PAGES_URL = '/pages';
export const NOTARIES_URL = '/notaries';

export const MESSENGER_URL = '/messengers';

export const ORDERS_ARCHIVE_SEARCH_URL = '/orders/archive/search';
export const ORDERS_ARCHIVE_GET_ZIP_URL = '/orders/archive/get-zip';
export const ORDERS_ARCHIVE_RECOVERY_URL = '/orders/archive/recovery';

export const NOTIFICATIONS_URL = '/notifications';

export const SITE_INDEPENDENCE_URL = '/site/independence';
export const LOG_URL = '/log';
export const LOG_REQUEST_TIME_URL = LOG_URL + '/request-time';
