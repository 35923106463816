<template>
  <div>
    <div :class="getClass()">
      <header-component
        v-if="isShow"
        :hide-event="handleHideEvent"
        :full-size-event="handleFullSizeEvent"
      />
      <slide-up-down v-model="isShow" :duration="100">
        <div class="slide-block">
          <body-component :messages="messages"/>
          <bottom-component/>
          <loader-component v-if="loader" />
        </div>
      </slide-up-down>
    </div>
    <div v-if="isShow" @click="handleHideEvent" class="overlay"></div>
  </div>
</template>

<script>
import { computed, ref, watchEffect, provide } from 'vue';
import { useStore } from 'vuex';
import { MESSENGER_HIDE } from "../../store/constants";
import { getDataRequest, addMessageRequest, addFileRequest, editMessageRequest } from "../../api/messenger";
import { getExtensionByName } from "../../helpers/file";
import HeaderComponent from "./header/HeaderComponent";
import LoaderComponent from "../LoaderComponent";
import BodyComponent from "./body/BodyComponent";
import BottomComponent from "./bottom/BottomComponent";

export default {
  name: 'MessengerComponent',
  components: {
      HeaderComponent,
      LoaderComponent,
      BodyComponent,
      BottomComponent
  },
  setup() {

      const store = useStore();
      const isFullSize = ref(false);
      const loader = ref(false);
      const messages = ref([]);
      const templates = ref([]);
      const isShow = computed(() => store.state.messenger.display);
      const messengerId = computed(() => store.state.messenger.id);
      const formFields = ref({
          id: null,
          text: '',
          files: { value: [], errors: [] },
          reply: null
      });
      const filePanel = ref(false);
      const fileRules = {
          maxSize: 52428800,
          allowedExtensions: ['jpg','jpeg','png','pdf','zip','heic','docx']
      };
      const currentMessage = ref({
          item: null,
          action: null
      });

      const handleHideEvent = (event) => {
          event.preventDefault();
          store.commit(MESSENGER_HIDE);
      };

      const handleFullSizeEvent = (event) => {
          event.preventDefault();
          isFullSize.value = !isFullSize.value;
      };

      const getClass = () => {
          const classes = ['messenger'];
          if (isFullSize.value) {
              classes.push('full');
          }
          return classes;
      };

      const loadData = () => {
          loader.value = true;
          getDataRequest(messengerId.value)
            .then(response => {
                messages.value = response.data.data.messages.map(item => {
                    item.loader = false;
                    return item;
                });
                templates.value = response.data.data.templates;
                loader.value = false;
            }).catch(err => console.log(err));
      };

      const clearForm = () => {
          formFields.value.id = null;
          formFields.value.text = '';
          formFields.value.reply = null;
          formFields.value.files.value.splice(0,formFields.value.files.value.length);
          currentMessage.value.item = null;
          currentMessage.value.action = null;
          filePanel.value = false;
      };

      const clear = () => {
          clearForm();
          setTimeout(function () {
              messages.value.splice(0,messages.value.length);
          },100);
      };

      const addMessage = async () => {

          const nowDate = new Date();
          const template = JSON.parse(JSON.stringify(templates.value.message));

          template.author.name = 'Вы';
          template.text = formFields.value.text;
          template.datetime = nowDate.getHours() + ':' + nowDate.getMinutes();
          template.type = 0;
          template.loader = true;

          const formData = new FormData();
          formData.append('Form[text]', formFields.value.text);

          if(formFields.value.reply) {
              template.reply = formFields.value.reply;
              formData.append('Form[replyId]', formFields.value.reply.id);
          }

          const index = messages.value.unshift(template)-1;

          addMessageRequest(messengerId.value, formData)
              .then(response => {
                  messages.value = messages.value.slice().reverse();
                  messages.value.splice(index, 1, response.data.data);
                  messages.value = messages.value.slice().reverse();
              }).catch(err => console.log(err));

      };

      const addFile = (file) => {

          const nowDate = new Date();
          const messageTemplate = JSON.parse(JSON.stringify(templates.value.message));
          messageTemplate.author.name = 'Вы';
          messageTemplate.text = '';
          messageTemplate.datetime = nowDate.getHours() + ':' + nowDate.getMinutes();
          messageTemplate.type = 1;
          messageTemplate.loader = true;
          const fileTemplate = JSON.parse(JSON.stringify(templates.value.file));
          fileTemplate.extension = getExtensionByName(file.name);
          messageTemplate.file = fileTemplate;

          const formData = new FormData();
          formData.append('Form[file]', file);

          const index = messages.value.unshift(messageTemplate)-1;

          addFileRequest(messengerId.value, formData)
              .then(response => {
                  messages.value = messages.value.slice().reverse();
                  messages.value.splice(index, 1, response.data.data);
                  messages.value = messages.value.slice().reverse();
              }).catch(err => console.log(err));

      };

      const handleSendSubmit = async (e) => {

          e.preventDefault();

          if(formFields.value.text === '' && !formFields.value.files.value.length) {
              return;
          }

          if(formFields.value.id) {

              const message = messages.value.find(item => item.id === formFields.value.id);
              message.loader = true;
              message.text = formFields.value.text;
              editMessageRequest(messengerId.value, formFields.value.id, {
                  text: formFields.value.text
              })
                .then(() => {
                    message.loader = false;
                }).catch(err => console.log(err));

          } else {
              if(formFields.value.text !== '') {
                  await addMessage();
              }

              for(let i=0; i<formFields.value.files.value.length; i++) {
                  addFile(formFields.value.files.value[i]);
              }
          }

          clearForm();
      };

      const handleShowFilePanel = (e) => {
          e.preventDefault();
          filePanel.value = !filePanel.value
      };

      const handleClearCurrentMessage = (e) => {
          e.preventDefault();
          currentMessage.value.item = null;
          currentMessage.value.action = null;
          formFields.value.id = null;
          formFields.value.text = '';
      };

      watchEffect(() => {
          if(isShow.value) {
              loadData();
          } else {
              clear();
          }
      });

      provide('formFields', formFields);
      provide('handleSendSubmit', handleSendSubmit);
      provide('handleShowFilePanel', handleShowFilePanel);
      provide('filePanel', filePanel);
      provide('fileRules', fileRules);
      provide('currentMessage', currentMessage);
      provide('handleClearCurrentMessage', handleClearCurrentMessage);
      provide('messengerId', messengerId);

      return {
          isShow,
          handleHideEvent,
          handleFullSizeEvent,
          getClass,
          messages,
          loader
      }
  }
}

</script>

<style scoped>

  .messenger {
    width: 550px;
    position: fixed;
    right: 10%;
    bottom: 0;
    -webkit-box-shadow: 0 0 10px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 10px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 0 10px 0 rgba(34, 60, 80, 0.2);
    z-index: 5;
  }

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  .slide-block {
    height: 500px;
    background-color: #f7fcf8;
    position: relative;
  }

  /** FULL **/
  .messenger.full {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .messenger.full .slide-block {
    width: 100%;
    height: 100%;
  }

  .messenger.full .body {
    bottom: 104px;
    height: auto;
    padding-bottom: 0;
  }

  .messenger.full .bottom {
    bottom: 44px;
  }

  .messenger.full .slide-up-down__container {
    height: 100%;
  }

  @media (max-width: 992px) {

    .messenger {
      width: 100%;
      right: 0;
      left: 0;
    }

  }

</style>
